<template>
  <div class="navigation-form-container">
    <error :errors="articleError" />
    <b-overlay :show="formBusy">
      <portlet :title="portletTitle" >
        <template v-if="action === 'edit'" v-slot:toolbar>
          <b-button :to="{name: 'articles.create'}" class="btn btn-label-success btn-sm border-0">
            يېڭىدىن قوشۇش
          </b-button>
        </template>
        <template v-slot:body>
          <b-form ref="permission" @submit.stop.prevent="onSubmit" :model="formData" @reset="onReset">
            <b-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_hidden" label="يۇشۇرۇپ قويامسىز ؟" label-for="is_hidden">
                  <b-form-checkbox id="is_hidden" switch v-model="formData.is_hidden"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2" v-if="formData.content_model !== 'download'">
                <b-form-group id="input-group-need_preface" label="مۇقەددىمىسى بارمۇ ؟" label-for="need_preface">
                  <b-form-checkbox id="need_preface" switch v-model="formData.need_preface"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2" v-if="formData.content_model !== 'download'">
                <b-form-group id="input-group-is_recommend" label="تەۋسىيەلەمسىز ؟" label-for="is_recommend">
                  <b-form-checkbox id="is_recommend" switch v-model="formData.is_recommend"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2" v-if="formData.content_model !== 'download'">
                <b-form-group id="input-group-has_one_price" label="توپ باھادا سېتىلامدۇ ؟" label-for="has_one_price">
                  <b-form-checkbox id="has_one_price" switch v-model="formData.has_one_price"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="6">
                <b-form-group :state="validateState('content_model')" aria-describedby="content-model-live-feedback" id="input-group-content_model" label="ئەسەر تۈرى" label-for="content-model">
                  <b-form-radio-group id="content-model" v-model="formData.content_model" @change="onChangeContentModel" :options="articleContentModels" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="content-model-live-feedback">
                    ئەسەر تۈرىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" sm="6" >
                <b-form-group id="input-group-title" label="ئەسەر ماۋزوسى" label-for="title" >
                  <b-form-input autofocus id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" placeholder="ئەسەر ماۋزوسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="title-live-feedback">
                    ئەسەر ماۋزوسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" v-if="formData.content_model === 'download'">
                <b-form-group id="input-group-keywords" label="ھالقىلىق سۆز" label-for="keywords" >
                  <b-form-input autofocus id="keywords" v-model="formData.keywords" :state="validateState('keywords')" type="text" aria-describedby="keywords-live-feedback" placeholder="ئەسەر ئىزدەش ھالقىلىق سۆزى كىرگۈزۈڭ، مەسىلەن كىملىك نۇمۇر دىگەندەك" />
                  <b-form-invalid-feedback id="keywords-live-feedback">
                    ئەسەر ئىزدەش ھالقىلىق سۆزى كىرگۈزۈڭ، مەسىلەن كىملىك نۇمۇرى دىگەندەك
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" v-if="formData.content_model !== 'download'" >
                <b-form-group id="input-group-total_episodes" label="ئەسەر قىسىم سانى" label-for="total_episodes" >
                  <b-form-input style="direction: ltr; width: 100%" min="1" max="99999" id="total_episodes" v-model="formData.total_episodes" :state="validateState('total_episodes')" type="number" aria-describedby="total_episodes-live-feedback" placeholder="ئەسەرنىڭ جەمئىي قىسىم سانىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="author_profit_ratio-live-feedback">
                    ئەسەر جەمئىي قانچە قىسىم، قىسىم سانىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" v-if="formData.content_model !== 'download'">
                <b-form-group id="input-group-author-profit" label="ئاپتور پايدا نىسپىتى" label-for="author-profit" >
                  <b-input-group prepend="%">
                    <b-form-input style="direction: ltr" min="0" max="100" step="0.1" id="author-profit" v-model="formData.author_profit_ratio" :state="validateState('author_profit_ratio')" type="number" aria-describedby="author_profit_ratio-live-feedback" placeholder="ئاپتور پايدا نىسپىتىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="author_profit_ratio-live-feedback">
                      ئاپتور پايدا نىسپىتىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" sm="6" v-if="formData.content_model !== 'download'">
                <b-form-group :state="validateState('authors')" aria-describedby="authors-live-feedback"  id="input-group-authors" label="ئاپتورلارنى تاللاڭ" label-for="authors" >
                  <multi-select
                    id="authors"
                    placeholder="تۆۋەندىكى يازغۇچىلاردىن تاللىسىڭىز بولىدۇ ."
                    select-label="قايتۇرۇش كونۇپكىسىنى بېسىپ تاللاڭ"
                    selected-label="تاللانغان"
                    deselect-label="قايتۇرۇش كونۇپكىسىنى بېسىپ ئۆچۈرۈڭ"
                    tag-position="bottom"
                    :searchable="false"
                    label="title"
                    track-by="title"
                    :multiple="true"
                    v-model="formData.authors"
                    :options="authorsForAll"
                  />
                  <b-form-invalid-feedback id="authors-live-feedback">
                    ئاپتورلارنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" >
                <b-form-group :state="validateState('categories')" aria-describedby="categories-live-feedback" id="input-group-categories" label="تۈرلەرنى تاللاڭ" label-for="categories" >
                  <multi-select
                    id="categories"
                    placeholder="تۆۋەندىكى تۈرلەردىن تاللىسىڭىز بولىدۇ ."
                    select-label="قايتۇرۇش كونۇپكىسىنى بېسىپ تاللاڭ"
                    selected-label="تاللانغان"
                    deselect-label="قايتۇرۇش كونۇپكىسىنى بېسىپ ئۆچۈرۈڭ"
                    tag-position="bottom"
                    :searchable="false"
                    label="title"
                    track-by="title"
                    :multiple="true"
                    v-model="formData.categories"
                    :options="categoriesForAll"
                  />
                  <b-form-invalid-feedback id="categories-live-feedback">
                    تۈرلەرنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" v-if="formData.has_one_price" >
                <b-form-group id="input-group-one_price" label="توپ باھاسى" label-for="one_price" >
                  <b-input-group prepend="￥">
                    <b-form-input style="direction: ltr" step="0.1" v-b-tooltip title="مەزكۇر ئەسەرنىڭ بارلىق قىسىلىرىنى مۇشۇ باھادا ساتىدىغانلىقىڭىزنى بىلدۈرىدۇ" min="0" max="100000" id="one_price" v-model="formData.one_price" :state="validateState('one_price')" type="number" aria-describedby="one_price-live-feedback" placeholder="ئەسەر توپ باھاسىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="one_price-live-feedback">
                      ئەسەر توپ باھاسىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3" sm="6" v-if="formData.has_one_price" >
                <b-form-group id="input-group-discount_price" label="ئېتبار توپ باھاسى" label-for="discount_price" >
                  <b-input-group prepend="￥">
                    <b-form-input style="direction: ltr" step="0.1" v-b-tooltip title="ئېتبار باھانى تولدۇرسىڭىز، ئەسلىدىكى توپ باھا ئەمەلدىن قېلىپ مەزكۇر ئېتبار باھادا سېتىلىدۇ" min="0" max="100000" id="discount_price" v-model="formData.discount_price" type="number" placeholder="ئەسەر ئېتبار توپ باھاسىنى كىرگۈزۈڭ" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group id="input-group-description" label="قىسقىچە چۈشەنچە" label-for="description" >
                  <b-form-textarea id="description" rows="4" v-model="formData.description" placeholder="ئەسەرنىڭ قىسقىچە چۈشەندۈرۈلىشىنى كىرگۈزۈڭ" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" md="3">
                <b-form-group :state="validateState('cover_image')" aria-describedby="cover-image-live-feedback" id="input-group-cover-image" label="ئەسەر ئالبوم رەسىمى" label-for="cover-image">
                  <image-uploader id="cover_image_uploader" @success="coverImageUploadSuccess" :img-url="oldCoverImage" :upload-form="{strategy: 'article'}" />
                  <b-form-invalid-feedback id="cover-image-live-feedback">
                    ئەسەر ئالبوم رەسىمى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3" v-if="formData.content_model !== 'download'">
                <b-form-group :state="validateState('banner_image')" aria-describedby="banner-image-live-feedback" id="input-group-banner-image" label="ئەسەر پىلاكات رەسىمى" label-for="banner-image">
                  <image-uploader id="banner_image_uploader" @success="bannerImageUploadSuccess" :img-url="oldBannerImage" :upload-form="{strategy: 'article'}" />
                  <b-form-invalid-feedback id="banner-image-live-feedback">
                    ئەسەر پىلاكات رەسىمى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-protocol" label="كېلىشىمگە قوشۇلىمەن" label-for="protocol">
                  <b-form-checkbox id="protocol" switch v-model="formData.protocol"/>
                  <template v-slot:description>
                    <p> « <a class="kt-link cursor-default" @click="onClickShowProtocol" >كېلىشىم</a> » گە قوشۇلغاندىن كىيىن داۋاملىق مەشغۇلات قىلىڭ. </p>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
    </b-overlay>
    <protocol-modal :state="protocolModalState" @confirm="onConfirmProtocol" @close="onCloseProtocolModal" />
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import Error from "../../partials/widgets/Error";
  import ProtocolModal from "../protocol/ProtocolModal";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import articleMixin from "../../../mixins/articleMixin";
  import authorMixin from "../../../mixins/authorMixin";
  import categoryMixin from "../../../mixins/categoryMixin";
  import validateStateMixin from "../../../mixins/common/validateStateMixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import Swal from "sweetalert2"
  import { mapGetters } from "vuex";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import collect from 'collect.js';
  import "vue-multiselect/dist/vue-multiselect.min.css";
  import MultiSelect from 'vue-multiselect';
  export default {
    name: "ArticleForm",
    mixins: [ formBusyMixin, validateStateMixin, validationMixin, articleMixin, authorMixin, categoryMixin, authorMixin, sweetAlertMixin ],
    components: { Portlet, Error, ImageUploader, MultiSelect, ProtocolModal },
    props: {
      action: {
        type: String,
        default: 'create'
      }
    },
    validations(){
      let validations = {
        title: { required },
        cover_image: { required },
        content_model: { required },
        categories: { required },
      };
      if ( this.formData.content_model === 'download' ){
        validations['keywords'] = { required };
        validations['one_price'] = { required };
      }else {
        validations['authors'] = { required };
        validations['total_episodes'] = { required };
        validations['author_profit_ratio'] = { required };
        validations['banner_image'] = { required };
        validations['one_price'] = { required: requiredIf(formData=>{
            return formData.has_one_price;
          })};
      }
      return {
        formData: validations
      }
    },
    computed: {
      portletTitle(){
        if ( this.action === 'create' )
          return 'يېڭىدىن ئەسەر قوشۇش';
        else if ( this.action === 'edit' )
          return 'ئەسەر ئۇچۇرىنى تەھرىرلەش';
      },
      oldCoverImage(){
        return this.action === 'edit' && this.formData.cover_image || '';
      },
      oldBannerImage(){
        return this.action === 'edit' && this.formData.banner_image || '';
      },
      ...mapGetters(["articleError", "authorsForAll", "categoriesForAll", "articleContentModels"]),
    },
    mounted() {},
    created() {
      this.getAuthorsForAll();
      this.getCategoriesForAll();
      if (this.action === 'edit'){
        let { id } = this.$route.params;
        this.findArticle(id)
        .then(response=>{
          let { data } = response;
          this.formData = data;
        }).catch(()=>{
          this.$router.replace({name: 'articles.list'});
        })
      }
    },
    data(){
      return {
        formData: {
          title: '',
          is_show: true,
          is_hidden: false,
          is_recommend: false,
          description: '',
          keywords: '',
          author_profit_ratio: null,
          content_model: 'voice',
          total_episodes: null,
          one_price: null,
          discount_price: null,
          has_one_price: false,
          need_preface: false,
          cover_image: '',
          banner_image: '',
          categories: [],
          authors: [],
          protocol: false,
        },
        protocolModalState: false,
        formBusy: false,
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
        }
        if ( ! this.formData.protocol ){
          return Swal.fire({
            title: 'ئەسكەرتىش!',
            text: 'كېلىشىمگە قوشۇلىشىڭىز كېرەك!',
            icon: 'error',
            confirmButtonText: 'بىلدىم',
          }).then(result=>{
            if ( result ){
              this.protocolModalState = true;
            }
          });
        }
        if (this.formData.content_model !== 'download'){
          delete this.formData.episode;
        }
        if (this.action === 'create'){
          this.createArticle(this.formData)
            .then(()=>{
              return this.$router.replace({name: "articles.list"});
            })
        }else if ( this.action === 'edit' ){
          this.updateArticle(this.formData)
            .then(()=>{
              this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
            })
        }
      },
      onReset(){
        return this.$router.replace({ name: "articles.list" });
      },
      coverImageUploadSuccess(response){
        // console.log(response);
        this.formData.cover_image = response.path;
        // this.formData.preview = response.url;
      },
      bannerImageUploadSuccess(response){
        // console.log(response);
        this.formData.banner_image = response.path;
        // this.formData.preview = response.url;
      },
      onCloseProtocolModal(){
        this.protocolModalState = ! this.protocolModalState;
      },
      onClickShowProtocol(){
        this.protocolModalState = true;
      },
      onConfirmProtocol(){
        this.formData.protocol = true;
      },
      onChangeContentModel(value){
        if ( value === 'download' ){
          this.formData.has_one_price = true;
        }
      }
    }
  }
</script>
